<template>
  <b-modal
    id="showCreateCountModal"
    title="Create Stock Count Request"
    @hidden="resetCreateCountModal"
    @show="resetCreateCountModal"
    style="top: 50px !important;"
  >
    <div style="display:flex; flex-wrap: wrap; justify-content: space-between; width:90%; margin:auto;">
      <div style="width:100%">
        <b-form-group id="input-group-01" style="padding:0; margin:0;" :label="messages.getTableColumnCountingType()" label-for="input-01">
          <b-form-select
          id="input-01"
          style="margin: 0;"
          @change="selectCountingType"
          :options="countingTypes"
          class="mb-3"
          value-field="item"
          text-field="name"
          >
          </b-form-select>
        </b-form-group>
      </div>
      <div style="width:100%">
        <b-form-group v-if="isPermitted(userManagement.user.permissions.createStockCount.update)"  style="padding:0; margin:0;" id="input-group-04" :label="messages.getTableColumnPlant()" label-for="input-04">
          <b-form-select
            @change="selectPlant"
            :options="plants"
            class="mb-3"
            style="margin: 0;"
            value-field="item"
            text-field="name"
          >
          </b-form-select>
        </b-form-group>
      </div>
      <div style="width:100%">
        <b-form-group id="input-group-02"  style="padding:0; margin:0;" :label="messages.getTableColumnWorkCenter()" label-for="input-02">
          <autocomplete-tags
            input-id="workCenters-field"
            datalist-id="workCenters-field-datalist"
            v-model="selectedWorkCenter"
            :limit='limit'
            :suggestions='workCenters'
            :disabled="disableWorkCentersField"
          >
          </autocomplete-tags>
        </b-form-group>
        <b-tooltip
          triggers="hover"
          title="If the email address is not visible here then the user has not logged in to KPT!"
          target="select-mechanic-01"
        >
        </b-tooltip>
        <b-form-group  id="input-group-05"  style="padding:0; margin:0;" :label="messages.getTableColumnConductor()" label-for="input-05">
          <b-form-select
            id="select-mechanic-01"
            v-model="selectedMechanic"
            class="mb-3"
            :options="mechanics"
            value-field="item"
            style="margin: 0;"
            text-field="name"
          >
          </b-form-select>
        </b-form-group>
      </div>
      <div style="width:100%">
        <b-form-group id="input-group-03"  style="padding:0; margin:0;" :label="messages.getTableColumnStorageLocation()" label-for="input-03">
          <autocomplete-tags
            input-id="storageLocations-field"
            datalist-id="storageLocations-field-datalist"
            v-model="selectedStorageLocation"
            :limit='limit'
            :suggestions='storageLocations'
            :disabled="disableStorageLocationField"
          >
          </autocomplete-tags>
        </b-form-group>
      </div>
      <div v-if="selectedCountingType !== 'wall_to_wall'" style="width:100%;">
        <b-form-group id="input-group-06" style="padding:0; margin:0;" :label="messages.getTableColumnMaterialCodes()" label-for="input-06">
          <div>
            <multiselect
              :loading="isLoading"
              v-model="selectedMaterialCodes"
              :options="materialCodes"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="false"
              :placeholder="messages.getLabelSelectMaterialCodePlaceHolder()"
              label="name"
              track-by="name"
              :preselect-first="true"
              @search-change="fetchMaterialCodes"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              >
                <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} {{ messages.getLabelSelectedMaterialCode() }}</span>
              </template>
            </multiselect>
          </div>
        </b-form-group>
      </div>
      <div style="width:100%;">
        <label for="deadline-datepicker">{{ messages.getTableColumnDeadline() }}</label>
        <b-form-datepicker
          id="deadline-datepicker"
          :start-weekday="1"
          v-model="shouldBeDoneAt"
          class="mb-2"
          :min="min"
          :max="max"
        >
        </b-form-datepicker>
      </div>
      <p v-if="disableFormSubmission" class="text-danger font-italic">{{ messages.getLabelAllFieldsMandatory() }}</p>
    </div>
    <template #modal-footer>
      <b-button v-if="disableFormSubmission && isPermitted(userManagement.user.permissions.createStockCount.update)" :disabled="true" style="margin:auto" size="sm">
        {{ messages.getLabelSendRequest() }}
      </b-button>
      <b-button  v-if="!disableFormSubmission && isPermitted(userManagement.user.permissions.createStockCount.update)" style="margin:auto" @click="submitCreatedCountRequest" size="sm" variant="primary">
        {{ messages.getLabelSendRequest() }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import moment from 'moment'
import AutocompleteTags from '../../components/UsersManagement/AutocompleteTags.vue'
import { INITIATED, PLANT, MECHANIC, SINGLE, MY_MATERIAL, WORK_CENTER, STORAGE_LOCATION, WALL_TO_WALL } from '@/constants/stock-count'
import Multiselect from 'vue-multiselect'
import { userManagement } from '@/constants/permitted-actions'
import { messages } from '@/utils/strings'
export default {
  name: 'CreateCountModal',
  components: { Multiselect, AutocompleteTags },
  data () {
    const minDate = moment().format('YYYY-MM-DD')
    const maxDate = moment().add(28, 'days').format('YYYY-MM-DD') // Max time is 28 days. Used to be 14 days.
    return {
      countingTypes: ['Single', 'Wall to Wall'],
      isLoading: false,
      limit: 1,
      min: minDate,
      max: maxDate,
      selectedCountingType: '',
      selectedMaterialCodes: [],
      selectedPlant: '',
      selectedStorageLocation: '',
      selectedWorkCenter: '',
      selectedMechanic: '',
      shouldBeDoneAt: '',
      messages
    }
  },
  created () {
    this.userManagement = userManagement
    if (this.isPermitted(userManagement.user.permissions.createStockCount.update)) {
      const queryParameters = { itemType: PLANT }
      this.$store.dispatch('stockCount/fetchItems', queryParameters)
    }
  },
  computed: {
    mechanics () {
      return this.$store.getters['stockCount/mechanics']
    },
    plants () {
      return this.$store.getters['stockCount/plants']
    },
    storageLocations () {
      return this.$store.getters['stockCount/storageLocations']
    },
    workCenters () {
      return this.$store.getters['stockCount/workCenters']
    },
    materialCodes () {
      return this.$store.getters['stockCount/materialCodes']
    },
    disablePlantRelatedFields () {
      return !this.isPermitted(userManagement.user.permissions.createStockCount.update)
    },
    disableWorkCentersField () {
      if (!this.selectedPlant) {
        return true
      }
      return false
    },
    disableStorageLocationField () {
      if (!this.selectedPlant) {
        return true
      }
      return false
    },
    disableFormSubmission () {
      if (
        this.isPermitted(userManagement.user.permissions.createStockCount.update) &&
        this.selectedCountingType &&
        this.shouldBeDoneAt &&
        // JSON.parse(JSON.stringify()) is used to get rid of observers Vue likes to add to things
        // This method of getting rid of observers has been suggested by the Vue creator
        JSON.parse(JSON.stringify(this.selectedStorageLocation)).length > 0 &&
        JSON.parse(JSON.stringify(this.selectedWorkCenter)).length > 0 &&
        JSON.parse(JSON.stringify(this.selectedMechanic)).length > 0 &&
        this.selectedPlant
      ) {
        if (this.selectedCountingType === SINGLE && this.materialCodes.length > 0) {
          // Form can be submitted
          return false
        }
        if (this.selectedCountingType === WALL_TO_WALL) {
          // Form can be submitted
          return false
        }
      }
      // Form submission is disabled by default
      return true
    }
  },
  methods: {
    resetCreateCountModal () {
      this.selectedCountingType = ''
      this.selectedMechanic = ''
      this.selectedPlant = ''
      this.selectedWorkCenter = [] // Because of AutocompleteTags this needs to be set to array
      this.selectedStorageLocation = [] // Because of AutocompleteTags this needs to be set to array
      this.shouldBeDoneAt = ''
      this.selectedMaterialCodes = []
    },
    isPermitted (permittedAction, permissionIds) {
      return this.$store.getters.isPermitted(permittedAction, permissionIds)
    },
    async fetchMaterialCodes (searchValue) {
      this.isLoading = true
      const queryParameters = { plant: this.selectedPlant, itemType: MY_MATERIAL, searchValue }
      await this.$store.dispatch('stockCount/fetchItems', queryParameters)
      this.isLoading = false
    },
    findMechanics () {
      if (this.selectedWorkCenter != null) {
        this.$store.dispatch('stockCount/fetchItems', { itemType: MECHANIC, workCenter: this.selectedWorkCenter })
      }
    },
    async selectPlant (plant) {
      // The function will select plant and also fetch the storageLocations and workCenters connected with the plant.
      if (this.ongoingQuery) {
        return
      }
      this.selectedPlant = plant
      const queryParameters = { plant, itemType: WORK_CENTER }
      await this.$store.dispatch('stockCount/fetchItems', queryParameters)
      queryParameters.itemType = STORAGE_LOCATION
      await this.$store.dispatch('stockCount/fetchItems', queryParameters)
    },
    selectCountingType (e) {
      if (e === 'Single') {
        this.selectedCountingType = SINGLE
      } else {
        this.selectedCountingType = WALL_TO_WALL
        this.selectedMaterialCodes = []
      }
    },
    async submitCreatedCountRequest () {
      const storageLocationAndWorkCenter = {}
      storageLocationAndWorkCenter[`${String(this.selectedStorageLocation)}`] = String(this.selectedWorkCenter) // Casting array to String
      const storageLocationsAndWorkCenters = []
      storageLocationsAndWorkCenters.push(storageLocationAndWorkCenter)
      const parameters = {
        status: INITIATED,
        type: this.selectedCountingType,
        plant: this.selectedPlant,
        conductedBy: this.selectedMechanic,
        shouldBeDoneAt: `${this.shouldBeDoneAt} 23:59:59`,
        materialCodes: this.selectedMaterialCodes.map(elem => elem.value),
        storageLocationAndWorkCenter: storageLocationsAndWorkCenters
      }
      this.isLoading = true
      await this.$store.dispatch('stockCount/createStockCountRequest', parameters)
        .then(async response => {
          if (response != null && response.status === 201) {
            this.$store.commit('stockCount/setSubmissionSuccessfulTitle', 'Stock Count Created!')
            this.$bvModal.show('showSubmissionSuccessfulModal')
          } else {
            this.$store.commit('stockCount/setSubmissionFailedTitle', 'Stock Count Creation Failed!')
            this.$bvModal.show('showSubmissionFailedModal')
          }
        })
        .catch(() => {
          this.$store.commit('stockCount/setSubmissionFailedTitle', 'Stock Count Creation Failed!')
          this.$bvModal.show('showSubmissionFailedModal')
        })
      await this.$store.commit('stockCount/clearStockCount')
      await this.$store.dispatch('stockCount/fetchNextPage')
      this.resetCreateCountModal()
      this.$bvModal.hide('showCreateCountModal')
      this.isLoading = false
    }
  },
  watch: {
    selectedWorkCenter (oldValue, newValue) {
      if (newValue) {
        this.findMechanics()
      }
    }
  }
}
</script>
<style scoped>
v-deep .modal-top-position {
  top: 50px !important;
}
#showCreateCountModal___BV_modal_outer_ {
  position: initial !important;
}
.text-area-style {
  margin-bottom: 4px;
}
</style>
