<template>
  <div style="width: 100% !important">
    <b-button
      v-if="isPermitted(userManagement.user.permissions.createStockCount.get) && isMobile()"
      style="padding: 2px; margin: 2px"
      variant="primary"
      size="sm"
      @click="$bvModal.show('showCreateCountModal')"
    >
    {{ messages.getActionCreateStockCount() }}
    </b-button>
    <span v-if="isMobile()">
      <order-count
        :order-by-options='orderByOptions'
        :order-by-column='orderByColumn'
        :sort-direction='sortDirection'
        :sort-direction-value='sortDirectionValue'
        @change-order-by-column='changeOrderByColumn'
        @change-sort-direction='changeSortDirection'
      />
    </span>
    <div style="width:100%; box-sizing:border-box; margin:0; padding:0 0 0 0; ">
       <div class="mobile" v-if="isMobile()">
          <b-card style="width: 100%; margin:0; padding:0;" v-for="(item, index) in items" :key="index">
            <b-list-group style="margin:0; padding:0;" flush>
              <b-list-group-item style="padding-left:0; padding-right:0"><b>{{ messages.getTableColumnStatus() }}: </b>{{item.status}}</b-list-group-item>
              <b-list-group-item style="padding-left:0; padding-right:0"><b>{{ messages.getTableColumnCreatedBy() }}: </b>{{item.created_by}}</b-list-group-item>
              <b-list-group-item style="padding-left:0; padding-right:0"><b>{{ messages.getTableColumnPlant() }}: </b>{{item.plant}} <b>{{ messages.getTableColumnType() }}: </b>{{item.type}}</b-list-group-item>
              <b-list-group-item style="padding-left:0; padding-right:0">
                <b> {{ messages.getTableColumnStorageLocation() }}: </b>{{item.storage_location}}
                <b> {{ messages.getTableColumnWorkCenter() }}: </b>{{item.work_center}}
                <span v-if="item.conducted_by"><b> {{ messages.getTableColumnConductor() }}: </b>{{item.conducted_by}}</span>
              </b-list-group-item>
              <b-list-group-item style="padding-left:0; padding-right:0"><b>{{ messages.getTableColumnCreated() }}: </b>{{item.created}}</b-list-group-item>
              <b-list-group-item style="padding-left:0; padding-right:0"><b>{{ messages.getTableColumnDeadline() }}: </b>{{item.should_be_done_at}}</b-list-group-item>
             </b-list-group>
             <b-button @click="mechanicView(item.id, item.status)" squared variant="primary">{{ messages.getActionView()}}</b-button>
            <b-button
              v-if="isPermitted(userManagement.user.permissions.editStockCount.get) && item.status == 'Recount'"
              @click="openEditStockCountModal(item)"
              squared
              variant="secondary"
              style="margin-right:2px;"
            >
            {{ messages.getActionEdit() }}
            </b-button>
          </b-card>
        </div>
      <b-tabs
        v-if="!isMobile()"
        active-nav-item-class="font-weight-bold text-uppercase border-bottom border-right-0 border-left-0 border-top-0 border-primary"
      >
        <b-tab title="Stock Count" active>
          <div style="width:100%;">
            <div class="desktop">
              <b-table
                bordered
                :fields="fields"
                :items="items"
                :no-local-sorting='true'
                responsive="sm"
                :sort-by.sync="orderByColumn"
                :sort-desc.sync="sortDirection"
                :sort-direction="sortDirectionWhenChangingTab"
                sort-icon-right
                striped
              >
                <template #cell(actions)="data">
                  <b-button
                    @click="mechanicView(data.item.id, data.item.status)"
                    squared
                    variant="primary"
                    style="margin-right: 1px;"
                  >
                  {{ messages.getActionView()}}
                  </b-button>
                  <div v-if="isPermitted(userManagement.user.permissions.editStockCount.get) && data.item.status == 'Recount'">
                    <b-button
                      @click="openEditStockCountModal(data.item)"
                      squared
                      variant="secondary"
                      style="margin-right:2px;"
                    >
                    {{ messages.getActionEdit() }}
                    </b-button>
                  </div>
                </template>
                <template v-slot:custom-foot class="desktop-pagination-container">
                  <b-tr id="footer-tr" class="mx-auto">
                    <b-td colspan="10">
                      <div
                        class="desktop-pagination-previous"
                      >
                        <b-button
                          v-show="hasPrev"
                          :disabled="ongoingQuery"
                          squared
                          class="text-uppercase text-primary"
                          @click="previousPage"
                        >
                          {{ messages.getActionPreviousPage() }}
                        </b-button>
                      </div>
                      <div
                        class="desktop-pagination-next"
                      >
                        <b-button
                          v-show="hasNext"
                          :disabled="ongoingQuery"
                          squared
                          class="text-uppercase text-primary"
                          @click="nextPage"
                        >
                          {{ messages.getActionNextPage() }}
                        </b-button>
                      </div>
                    </b-td>
                  </b-tr>
                </template>
              </b-table>
            </div>
          </div>
        </b-tab>
        <template #tabs-end>
          <b-button
            style="padding: 2px; margin: 2px;"
            v-if="isPermitted(userManagement.user.permissions.createStockCount.get) && !isMobile()"
            variant="primary"
            size="sm"
            @click="$bvModal.show('showCreateCountModal')"
          >
          {{ messages.getActionCreateStockCount() }}
          </b-button>
        </template>
      </b-tabs>
    </div>
    <submission-successful></submission-successful>
    <submission-failed></submission-failed>
    <start-count-modal />
    <create-count-modal />
    <edit-stock-count-modal :stock-count="stockCount" :isMobile="isMobile" />
    <cancel-modal :id="requestToBeCancelled" />
  </div>
</template>

<script>
import { messages } from '@/utils/strings'
import SubmissionSuccessful from '../MechanicView/SubmissionSuccessful'
import SubmissionFailed from '../MechanicView/SubmissionFailed'
import CreateCountModal from '../NotificationsView/CreateCountModal'
import StartCountModal from '../NotificationsView/StartCountModal'
import EditStockCountModal from '../NotificationsView/EditStockCountModal'
import CancelModal from '../NotificationsView/CancelModal'
import OrderCount from '../Notifications/Mobile/OrderCount'
import { CREATED_BY, PLANT, STORAGE_LOCATION, WORK_CENTER, CONDUCTED_BY, TYPE, CREATED, SHOULD_BE_DONE_AT, STATUS, ASC, DESC } from '@/constants/stock-count'
import { userManagement } from '@/constants/permitted-actions'
import { MAX_RESULTS, MOBILE_MAX_RESULTS } from '@/constants/shared-constants'
let INFINITE = null

export default {
  name: 'Notifications',
  components: { StartCountModal, CreateCountModal, CancelModal, EditStockCountModal, SubmissionSuccessful, SubmissionFailed, OrderCount },
  data () {
    return {
      currentPage: 1,
      requestToBeCancelled: null,
      orderByColumn: this.$store.getters['stockCount/orderByColumn'] || PLANT,
      sortDirection: this.$store.getters['stockCount/sortDirection'] || false, // false means ascencing order and true descending order
      sortDirectionWhenChangingTab: 'last', // last means use the sortDirection that was last used
      fields: [
        { label: messages.getTableColumnCreatedBy(), key: CREATED_BY, sortable: true },
        { label: messages.getTableColumnPlant(), key: PLANT, sortable: true },
        { label: messages.getTableColumnStorageLocation(), key: STORAGE_LOCATION, sortable: true },
        { label: messages.getTableColumnConductor(), key: CONDUCTED_BY, sortable: true },
        { label: messages.getTableColumnWorkCenter(), key: WORK_CENTER, sortable: true },
        { label: messages.getTableColumnType(), key: TYPE, sortable: true },
        { label: messages.getTableColumnCreated(), key: CREATED, sortable: true },
        { label: messages.getTableColumnDeadline(), key: SHOULD_BE_DONE_AT, sortable: true },
        { label: messages.getTableColumnStatus(), key: STATUS, sortable: true },
        { label: messages.getTableColumnActions(), key: 'actions', sortable: false }
      ],
      mobileView: false,
      messages,
      orderByOptions: [
        { label: messages.getTableColumnConductor(), key: CONDUCTED_BY },
        { label: messages.getTableColumnCreated(), key: CREATED },
        { label: messages.getTableColumnCreatedBy(), key: CREATED_BY },
        { label: messages.getTableColumnDeadline(), key: SHOULD_BE_DONE_AT },
        { label: messages.getTableColumnPlant(), key: PLANT },
        { label: messages.getTableColumnStatus(), key: STATUS },
        { label: messages.getTableColumnStorageLocation(), key: STORAGE_LOCATION },
        { label: messages.getTableColumnType(), key: TYPE },
        { label: messages.getTableColumnWorkCenter(), key: WORK_CENTER }
      ],
      stockCount: {}
    }
  },
  computed: {
    total () {
      return this.$store.getters['stockCount/total']
    },
    perPage () {
      return this.$store.getters['stockCount/defaultMaxResults']
    },
    items () {
      if (this.isMobile()) {
        return this.$store.getters['stockCount/mobileStockCountItems']
      }
      return this.$store.getters['stockCount/stockCountItems'](this.page)
    },
    ongoingQuery () {
      return this.$store.getters['stockCount/ongoingQuery']
    },
    page: {
      get () {
        return this.$store.getters['stockCount/page']
      },
      set (page) {
        return this.$store.commit('stockCount/setPage', page)
      }
    },
    lastPage () {
      return this.$store.getters['stockCount/lastPage']
    },
    hasPrev () {
      return this.$store.getters['stockCount/hasPrev']
    },
    hasNext () {
      return this.$store.getters['stockCount/hasNext']
    },
    orderByColumnUpdated () {
      return this.orderByColumn
    },
    sortDirectionUpdated () {
      return this.sortDirection
    },
    sortDirectionValue () {
      return this.sortDirection ? DESC : ASC
    },
    maxResults () {
      return this.isMobile() ? MOBILE_MAX_RESULTS : MAX_RESULTS
    }
  },
  methods: {
    isPermitted (permittedAction, permissionIds) {
      return this.$store.getters.isPermitted(permittedAction, permissionIds)
    },
    changeOrderByColumn (column) {
      this.orderByColumn = column
    },
    changeSortDirection (direction) {
      this.sortDirection = direction === DESC ? true : false // eslint-disable-line
    },
    openEditStockCountModal (stockCount) {
      this.stockCount = stockCount
      this.$bvModal.show('showEditStockCountModal')
    },
    cancel (id) {
      this.requestToBeCancelled = id
      this.$bvModal.show('cancelModal')
    },
    mechanicView (id) {
      // Opens the mechanic view where the Stock Counting is done
      const elem = this.items.filter(el => el.id === id)?.[0]
      this.$router.push({
        name: 'MechanicView',
        params: {
          id: `${id}`,
          stockCount: { ...elem }
        }
      })
    },
    changePage (obj) {
      this.currentPage = obj
      this.fetchData()
    },
    isMobile () {
      return this.$screen.width <= 991
    },
    nextPage () {
      if (this.page === this.lastPage.index) {
        if (!this.lastPage.hasNext) {
          throw Error('No pages to fetch')
        }
        // fetchNextPage should be used here
        this.$store.dispatch('stockCount/fetchNextPage')
          .then(() => {
            this.page = this.lastPage.index
          })
      } else {
        this.page = this.page + 1
      }
    },
    previousPage () {
      // this should not do the query as the previous page is stored already
      this.page = this.page - 1
    },
    infiniteScroll () {
      INFINITE = window.onscroll = () => {
        if (this.isMobile()) {
          this.mobileView = true
          const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.body.scrollHeight - window.innerHeight
          // When we reach the bottom of window we load more items from database
          if (bottomOfWindow && !this.ongoingQuery) {
            if (this.hasNext) {
              const viewWrapper = document.scrollingElement
              const scrollPosition = viewWrapper.scrollTop
              this.$store.dispatch('stockCount/fetchNextPage', this.maxResults).then(() => {
                viewWrapper.scrollTop = scrollPosition
              })
            }
          }
        } else {
          this.mobileView = false
        }
      }
      INFINITE()
    },
    async updatedDataOrderAndSortDirection () {
      await this.$store.commit('stockCount/clearStockCount')
      await this.$store.commit('stockCount/setOrderByColumn', this.orderByColumn)
      await this.$store.commit('stockCount/setSortDirection', this.sortDirection)
      await this.$store.dispatch('stockCount/fetchNextPage', this.maxResults)
    }
  },
  created () {
    this.userManagement = userManagement
    if (this.$store.getters['stockCount/firstFetchDone'] === false) {
      if (this.ongoingQuery) {
        return
      }
      this.$store.dispatch('stockCount/fetchNextPage', this.maxResults)
    }
  },
  mounted () {
    this.infiniteScroll()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.INFINITE)
  },
  watch: {
    async orderByColumnUpdated () {
      await this.updatedDataOrderAndSortDirection()
    },
    async sortDirectionUpdated () {
      await this.updatedDataOrderAndSortDirection()
    }
  }
}
</script>
<style scoped>
.mobile {
  width: 100%;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
.desktop-pagination-container {
  display: flex;
  margin-bottom: 20px;
}
.desktop-pagination-previous {
  float: left;
}
.desktop-pagination-next {
  float: right;
}
#footer-tr {
  flex-direction: column;
}
</style>
