<template>
    <b-modal id="showCountRequestModal" @hidden="resetSuccessful" :ok-title="(successfulSubmit || successfulSave) ? 'Done' : ''" :ok-only="okOnly" centered :title=" !successfulSubmit && !successfulSave ? 'Counting Request' : ''" @ok="resetSuccessful">
        <template v-if="!successfulSubmit && !successfulSave">
          <b-form @reset="onReset" v-if="show">
            <b-row>
              <b-col>
                <b-form-group id="input-group-1" style="padding:0; margin:0;" :label="messages.getFieldWorkCenters()+':'" label-for="input-1">
                  <div>
                    <multiselect v-model="selectedWorkCenters" :options="workCenters" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :placeholder="messages.getLabelSelectWorkCenterPlaceHolder()" label="name" track-by="name" :preselect-first="true">
                      <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} {{ messages.getLabelWorkCenterSelected() }}</span></template>
                    </multiselect>
                  </div>
                  <p class="font-italic"><span class="text-danger">*</span>{{ messages.getFieldRequired() }}</p>
                </b-form-group>
                <b-form-group id="input-group-2" style="padding:0; margin:0;" :label="messages.getFieldStorageLocation()+':'" label-for="input-2">
                  <div>
                    <multiselect v-model="selectedStorageLocations" :options="storageLocations" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :placeholder="messages.getLabelSelectStorageLocationPlaceHolder()" label="name" track-by="name" :preselect-first="true">
                      <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} {{ messages.getLabelStorageLocationSelected() }}</span></template>
                    </multiselect>
                  </div>
                  <p class="font-italic"><span class="text-danger">*</span>{{ messages.getFieldRequired() }}</p>
                </b-form-group>
                <b-form-group id="input-group-3" style="padding:0; margin:0;" :label="messages.getFieldMaterialCode()+':'" label-for="input-3">
                  <div>
                   <multiselect v-model="selectedMaterialCodes" :options="materialCodes" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :placeholder="messages.getLabelSelectMaterialCodePlaceHolder()" label="name" track-by="name" :preselect-first="true">
                      <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} {{ messages.getLabelMaterialCodeSelected() }}</span></template>
                    </multiselect>
                  </div>
                  <p class="font-italic"><span class="text-danger">*</span>{{ messages.getFieldRequired() }}</p>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                :label="messages.getFieldDescription()"
                label-for="textarea-formatter"
                class="mb-0"
                >
                  <b-form-textarea
                    id="textarea-formatter"
                    v-model="description"
                    :placeholder="messages.getLabelEnterTextPlaceHolder()"
                  ></b-form-textarea>
                  <p class="font-italic"><span class="text-danger">*</span>{{ messages.getFieldRequired() }}</p>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </template>
        <template v-if="successfulSubmit">
          <div style="min-height:200px;" class="d-flex justify-content-center align-items-center">
            <b-icon-check2-circle scale="6" variant="success"/>
          </div>
          <div style="text-align:center; margin: auto; width:80%;">
            <h3> {{ messages.getLabelCountingRequest() }}</h3>
            <p>{{ messages.getLabelCountingRequestSubmittedSuccessfully() }}</p>
          </div>
        </template>
        <template v-if="successfulSave">
          <div style="min-height:200px;" class="d-flex justify-content-center align-items-center">
            <b-icon-journal-bookmark-fill scale="6" variant="primary"/>
          </div>
          <div style="text-align:center; margin: auto; width:80%;">
            <h3>{{ messages.getLabelCountingRequest() }}</h3>
            <p>{{ messages.getLabelCountingRequestSavedAsDraft() }}</p>
          </div>
        </template>
        <template #modal-footer v-if="!successfulSubmit && !successfulSave">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button @click="handleSave" size="sm" variant="outline-primary">
            {{ messages.getActionSaveAsDraft() }}
          </b-button>
          <b-button @click="handleSubmit" variant="primary" size="sm">
            {{ messages.getActionSaveNSubmit() }}
          </b-button>
        </template>
      </b-modal>
  </template>
<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import { userManagement } from '@/constants/permitted-actions'
import { BIconCheck2Circle, BIconJournalBookmarkFill } from 'bootstrap-vue'
import { messages } from '@/utils/strings'
const url = process.env.VUE_APP_STOCK_API_ENDPOINT
export default {
  name: 'StartCountModal',
  components: { Multiselect, BIconCheck2Circle, BIconJournalBookmarkFill },
  data () {
    return {
      show: true,
      successfulSubmit: false,
      successfulSave: false,
      selectedMaterialCodes: [],
      selectedStorageLocations: [],
      selectedWorkCenters: [],
      description: '',
      storageLocations: [],
      workCenters: [],
      materialCodes: [{ name: 'A', value: 'A' }, { name: 'B', value: 'B' }, { name: 'C', value: 'C' }, { name: 'D', value: 'D' }],
      messages
    }
  },
  computed: {
    okOnly () {
      return this.successfulSave || this.successfulSubmit
    }
  },
  methods: {
    isPermitted (permittedAction, permissionIds) {
      return this.$store.getters.isPermitted(permittedAction, permissionIds)
    },
    onReset (event) {
      event.preventDefault()
      // Reset our form values
      this.selectedMaterialCodes = null
      this.selectedStorageLocations = null
      this.selectedWorkCenters = null
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    handleSave (event) {
      event.preventDefault()
      this.successfulSave = true
    },
    handleSubmit (event) {
      event.preventDefault()
      this.successfulSubmit = true
    },
    resetSuccessful () {
      this.successfulSubmit = false
      this.successfulSave = false
    }
  },
  created () {
    this.userManagement = userManagement
    if (this.isPermitted(userManagement.user.permissions.createStockCount.update)) {
      axios.get(`${url}/api/v2/relationship/storage_location`)
        .then((data) => {
          this.storageLocations = data.data.storage_locations.map((el) => {
            return {
              name: el,
              value: el
            }
          })
        })
        .catch(err => console.error(err))
      axios.get(`${url}/api/v2/relationship/work_center`)
        .then((data) => {
          this.workCenters = data.data.work_centers.map((el) => {
            return {
              name: el,
              value: el
            }
          })
        })
        .catch(err => console.error(err))
    }
  }
}
</script>
<style scoped>
</style>
