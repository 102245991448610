<template>
  <page-layout>
    <template v-slot:topBar>
      <top-panel class="d-block d-lg-none" />
    </template>
    <stock-count />
  </page-layout>
</template>

<script>
import TopPanel from '../components/Notifications/Mobile/NotificationTopPanel'
import PageLayout from '../components/PageLayout'
import StockCount from '../components/Notifications/StockCount'

export default {
  name: 'Notifications',
  components: { PageLayout, TopPanel, StockCount }
}
</script>
<style scoped>
</style>
