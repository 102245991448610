<template>
  <b-modal
    id="showEditStockCountModal"
    title="Edit Stock Count"
    @hidden="resetEditStockCountModal"
    @show="resetEditStockCountModal"
  >
    <div style="display: flex; flex-wrap: wrap; justify-content: space-between; width: 90%; margin: auto;">
      <div style="width:100%;">
        <div style="width:100%">
          <b-form-group id="input-group-99"  style="padding:0; margin:0;" :label="messages.getTableColumnWorkCenter()" label-for="input-99">
            <autocomplete-tags
              input-id="workCenters-field"
              datalist-id="workCenters-field-datalist"
              v-model="selectedWorkCenter"
              :limit='limit'
              :suggestions='workCenters'
            >
            </autocomplete-tags>
          </b-form-group>
          <b-form-group  id="input-group-98"  style="padding:0; margin:0;" :label="messages.getTableColumnConductor()" label-for="input-98">
            <b-form-select
              v-model="selectedMechanic"
              :options="mechanics"
              class="mb-3"
              value-field="item"
              style="margin: 0;"
              text-field="name"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button v-if="disableFormSubmission" :disabled="true" style="margin:auto" size="sm">
        {{ messages.getActionSave() }}
      </b-button>
      <b-button  v-if="!disableFormSubmission" style="margin:auto" @click="saveEditedStockCount" size="sm" variant="primary">
        {{ messages.getActionSave() }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import AutocompleteTags from '../../components/UsersManagement/AutocompleteTags'
import { MECHANIC, WORK_CENTER } from '@/constants/stock-count'
import { userManagement } from '@/constants/permitted-actions'
import { MAX_RESULTS, MOBILE_MAX_RESULTS } from '../../constants/shared-constants'
import { messages } from '@/utils/strings'
export default {
  name: 'EditStockCountModal',
  components: { AutocompleteTags },
  props: {
    stockCount: Object,
    isMobile: Function
  },
  data () {
    return {
      limit: 1, // Just one work center can be selected
      maxResults: this.isMobile() ? MOBILE_MAX_RESULTS : MAX_RESULTS,
      ongoingQuery: false,
      selectedMechanic: '',
      selectedWorkCenter: [],
      messages
    }
  },
  created () {
    this.userManagement = userManagement
  },
  computed: {
    workCenters () {
      return this.$store.getters['stockCount/workCenters']
    },
    mechanics () {
      return this.$store.getters['stockCount/mechanics']
    },
    disableFormSubmission () {
      // Work center is mandatory, conductor is not because some work centers might not have a person connected them
      // in our database.
      if (this.isPermitted(userManagement.user.permissions.editStockCount.update) && this.selectedWorkCenter.length === this.limit) {
        return false
      }
      return true
    }
  },
  methods: {
    isPermitted (permittedAction, permissionIds) {
      return this.$store.getters.isPermitted(permittedAction, permissionIds)
    },
    async getWorkCenters () {
      if (this.ongoingQuery) {
        return
      }
      const plant = this.stockCount.plant
      const queryParameters = { plant, itemType: WORK_CENTER }
      await this.$store.dispatch('stockCount/fetchItems', queryParameters)
    },
    findMechanics () {
      if (this.selectedWorkCenter.length === this.limit) {
        this.$store.dispatch('stockCount/fetchItems', { itemType: MECHANIC, workCenter: this.selectedWorkCenter })
      }
    },
    resetEditStockCountModal () {
      this.selectedWorkCenter = [] // Because of AutocompleteTags this needs to be set to array
      this.selectedMechanic = ''
      this.$store.commit('stockCount/clearMechanics')
    },
    async saveEditedStockCount () {
      const parameters = {
        workCenter: this.selectedWorkCenter[0], // Only one item can be in selectedWorkCenter array
        conductedBy: this.selectedMechanic,
        stockCountId: this.stockCount.id
      }
      this.$store.dispatch('stockCount/saveEditedStockCount', parameters)
        .then(response => {
          if (response != null && response.status === 204) {
            this.$bvModal.hide('showEditStockCountModal')
            this.$store.commit('stockCount/setSubmissionSuccessfulTitle', 'Stock Count Edited!')
            this.$bvModal.show('showSubmissionSuccessfulModal')
            this.$store.commit('stockCount/clearStockCount')
            this.$store.dispatch('stockCount/fetchNextPage', this.maxResults)
          } else {
            this.$bvModal.hide('showEditStockCountModal')
            this.$store.commit('stockCount/setSubmissionFailedTitle', 'Editing Stock Count Failed!')
            this.$bvModal.show('showSubmissionFailedModal')
          }
        })
        .catch(() => {
          this.$bvModal.hide('showEditStockCountModal')
          this.$store.commit('stockCount/setSubmissionFailedTitle', 'Editing Stock Count Failed!')
          this.$bvModal.show('showSubmissionFailedModal')
        })
      this.resetEditStockCountModal()
    }
  },
  watch: {
    stockCount (oldValue, newValue) {
      if (newValue) {
        this.getWorkCenters()
      }
    },
    selectedWorkCenter (oldValue, newValue) {
      if (newValue) {
        this.findMechanics()
      }
    }
  }
}

</script>

<style scoped>
</style>
